<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Liste des tickets</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                ref="vgt"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :isLoading.sync="isLoading"
                :pagination-options="pagination"
                :totalRows="remoteData.totalRecords"
                :rows="remoteData.rows"
                :columns="columns"
                :sort-options="{
                    enabled: false,
                    initialSortBy: { field: 'id', type: 'desc' },
                }"
                styleClass="vgt-table striped bordered condensed"
            >
                <div slot="table-actions" class="p-2 pt-0 pb-0">
                    <select
                        class="uk-select uk-border-rounded"
                        v-model="serverParams.extraFilter.closed"
                        @change="loadItems"
                    >
                        <option :value="false">Tickets ouverts</option>
                        <option :value="true">Tickets fermés</option>
                    </select>
                </div>
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'actions'" class="uk-text-center">
                        <vk-button
                            @click="$router.push({ name: 'ticket_view', params: { ticketId: props.row.id } })"
                            type="primary"
                            class="uk-border-rounded uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                        >
                            <font-awesome-icon icon="fa-regular fa-eye" />
                        </vk-button>
                    </div>
                    <div v-else-if="props.column.field === 'status'">
                        <StatusElement
                            v-for="(status, index) in props.formattedRow['status']"
                            :key="'status' + index"
                            :is-status-present-in-current-user-status-list="
                                isStatusPresentInCurrentUserStatusList(status)
                            "
                            :sta="status"
                        />
                    </div>
                    <div v-else-if="props.column.field === 'mainPurpose'" style="white-space: pre-line">
                        {{ props.formattedRow[props.column.field] }}
                        <!-- todo: clean up data in backend (<br />) -->
                    </div>
                    <div v-else-if="props.column.field === 'quotations'">
                        <div
                            v-for="quotation in props.formattedRow[props.column.field]"
                            :key="'quotation' + quotation.id"
                        >
                            <span
                                class="uk-label uk-border-rounded uk-padding-small uk-margin-small"
                                style="cursor: pointer"
                                @click="downloadDocument(quotation.document.randomIdentifier)"
                                >{{
                                    quotation.mainPurpose.substring(0, 35) +
                                    (quotation.mainPurpose.length > 35 ? ' ...' : '')
                                }}
                                - {{ quotation.amountWoTax }}€HT / {{ quotation.amountWithTax }}€TTC
                                <font-awesome-icon
                                    icon="fa-solid fa-file-arrow-down"
                                    class="uk-margin-small-left"
                                    style="font-size: 1rem"
                                />
                            </span>
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'invoices'">
                        <div v-for="invoice in props.formattedRow[props.column.field]" :key="'invoice' + invoice.id">
                            <span
                                class="uk-label uk-border-rounded uk-padding-small uk-margin-small"
                                style="cursor: pointer"
                                @click="downloadDocument(invoice.document.randomIdentifier)"
                                >{{
                                    invoice.mainPurpose.substring(0, 35) +
                                    (invoice.mainPurpose.length > 35 ? ' ...' : '')
                                }}
                                - {{ invoice.amountWoTax }}€HT / {{ invoice.amountWithTax }}€TTC
                                <font-awesome-icon
                                    icon="fa-solid fa-file-arrow-down"
                                    class="uk-margin-small-left"
                                    style="font-size: 1rem"
                                />
                            </span>
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'property.address.formattedAddress'">
                        <div class="m-2">
                            {{ props.formattedRow[props.column.field] }}
                        </div>
                        <SimpleAccordion v-if="hiddenFields(props.formattedRow).length > 0">
                            <template v-slot:accordionTitle>Plus d'infos</template>
                            <template v-slot:accordionContent>
                                <div class="p-2">
                                    <div
                                        v-if="
                                            hiddenFields(props.formattedRow).includes('status') &&
                                            props.formattedRow['status'].length
                                        "
                                        class="mb-3"
                                    >
                                        <p>Statuts</p>
                                        <div>
                                            <StatusElement
                                                v-for="(status, index) in props.formattedRow['status']"
                                                :key="'status' + index"
                                                :is-status-present-in-current-user-status-list="
                                                    isStatusPresentInCurrentUserStatusList(status)
                                                "
                                                :sta="status"
                                            />
                                        </div>
                                    </div>
                                    <div v-if="hiddenFields(props.formattedRow).includes('property.name')">
                                        <p>Propriété : {{ props.formattedRow['property.name'] }}</p>
                                    </div>
                                    <div v-if="hiddenFields(props.formattedRow).includes('mainPurpose')">
                                        <p>Objet du ticket : {{ props.formattedRow['mainPurpose'] }}</p>
                                    </div>
                                    <div v-if="hiddenFields(props.formattedRow).includes('missionOrders')">
                                        <p>Nombre d'ordre de mission : {{ props.formattedRow['missionOrders'] }}</p>
                                    </div>
                                    <div
                                        v-if="
                                            hiddenFields(props.formattedRow).includes('quotations') &&
                                            props.formattedRow['quotations'].length
                                        "
                                    >
                                        <p>Devis :</p>
                                        <div
                                            v-for="quotation in props.formattedRow['quotations']"
                                            :key="'quotation' + quotation.id"
                                        >
                                            <button
                                                class="btn btn-primary btn-sm m-1"
                                                @click="downloadDocument(quotation.document.randomIdentifier)"
                                            >
                                                {{
                                                    quotation.mainPurpose.substring(0, 35) +
                                                    (quotation.mainPurpose.length > 35 ? ' ...' : '')
                                                }}
                                                - {{ quotation.amountWoTax }}€HT / {{ quotation.amountWithTax }}€TTC
                                                <font-awesome-icon
                                                    icon="fa-solid fa-file-arrow-down"
                                                    class="uk-margin-small-left"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            hiddenFields(props.formattedRow).includes('invoices') &&
                                            props.formattedRow['invoices'].length
                                        "
                                    >
                                        <p>Factures :</p>
                                        <div
                                            v-for="invoice in props.formattedRow['invoices']"
                                            :key="'invoice' + invoice.id"
                                        >
                                            <button
                                                class="btn btn-primary btn-sm"
                                                @click="
                                                    downloadDocument(
                                                        invoice.document.randomIdentifier,
                                                        invoice.document.originalName,
                                                    )
                                                "
                                            >
                                                {{
                                                    invoice.mainPurpose.substring(0, 35) +
                                                    (invoice.mainPurpose.length > 35 ? ' ...' : '')
                                                }}
                                                - {{ invoice.amountWoTax }}€HT / {{ invoice.amountWithTax }}€TTC
                                                <font-awesome-icon
                                                    icon="fa-solid fa-file-arrow-down"
                                                    class="uk-margin-small-left"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </SimpleAccordion>
                    </div>
                    <div v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'
import SimpleAccordion from '@/components/SimpleAccordion'
import StatusElement from '@/components/StatusElement.vue'

export default {
    name: 'Tickets',
    components: { StatusElement, SimpleAccordion },
    data: () => ({
        isLoading: false,
        serverParams: {
            extraFilter: {
                closed: false,
            },
            columnFilters: {},
            sort: [
                {
                    field: 'id',
                    type: 'desc',
                },
            ],
            page: 1,
            perPage: 10,
        },
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
            // infoFn: (params) => `Page ${params.currentPage}`,
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
        currentUserStatus: [],
    }),
    computed: {
        columns: function () {
            return [
                {
                    label: '#',
                    field: 'id',
                    sortable: true,
                    hidden: this.shouldBeHidden('xs'),
                },
                {
                    label: 'Adresse',
                    field: 'property.address.formattedAddress',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                    hidden: this.shouldBeHidden('xs'),
                    tdClass: 'p-0',
                },
                {
                    label: 'Propriété',
                    field: 'property.name',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                    hidden: this.shouldBeHidden('xl'),
                },
                {
                    label: 'Statuts',
                    field: 'status',
                    filterOptions: {
                        enabled: true,
                    },
                    hidden: this.shouldBeHidden('xl'),
                },
                {
                    label: 'Objet',
                    field: 'mainPurpose',
                    filterOptions: {
                        enabled: true,
                    },
                    hidden: this.shouldBeHidden('sm'),
                },
                {
                    label: 'Ordres de mission',
                    field: 'missionOrders',
                    hidden: this.shouldBeHidden('xl'),
                },
                {
                    label: 'Devis',
                    field: 'quotations',
                    hidden: this.shouldBeHidden('giga'),
                },
                {
                    label: 'Factures',
                    field: 'invoices',
                    hidden: this.shouldBeHidden('giga'),
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    tdClass: 'text-center center-align',
                    width: '1%',
                },
            ]
        },
    },
    methods: {
        hiddenFields: function (formattedRow) {
            let hiddenFields = []
            this.columns.forEach((column) => {
                if (column.hidden) {
                    hiddenFields.push(column.field)
                    if (['invoices', 'quotations', 'status'].includes(column.field)) {
                        if (!formattedRow[column.field].length) {
                            hiddenFields.pop()
                        }
                    }
                }
            })

            return hiddenFields
        },
        shouldBeHidden: function (at) {
            const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga']
            if (!bp.includes(at)) {
                return false
            }

            return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams(params)
            this.loadItems()
        },
        loadItems() {
            backendApi.getTickets(store.state.login.user.token, this.serverParams).then((data) => {
                this.remoteData.totalRecords = data.totalRecords
                this.remoteData.rows = data.rows
                this.updateParams({
                    page: data.currentPage,
                    perPage: data.maxResult,
                })
            })
        },
        downloadDocument(identifier, originalName) {
            backendApi.downloadDocument(store.state.login.user.token, identifier, originalName)
        },
        async getCurrentUserStatus() {
            try {
                this.currentUserStatus = await backendApi.getStatus(store.state.login.user.token)
            } catch (error) {
                console.error("Une erreur s'est produite : ", error)
                throw error
            }
        },
        isStatusPresentInCurrentUserStatusList(status) {
            return this.currentUserStatus.some((item) => item.id === status.id && item.isPublic === status.isPublic)
        },
    },
    mounted() {
        this.getCurrentUserStatus()
    },
}
</script>

<style>
.center-align {
    vertical-align: middle !important;
}
.public-status-for-current-user {
    background-color: #2cb4db !important;
}
.private-status-for-current-user {
    background-color: #172b4d !important;
}
.public-status-for-another-user {
    background-color: #41b883 !important;
}
.private-status-for-another-user {
    background-color: #084a2c !important;
}
</style>
